import React from 'react'
import '../../assets/styles/footer.scss'
import logo from '../../assets/images/logo.svg'
import fb from '../../assets/images/ico-fb.svg'
import ig from '../../assets/images/ico-ig.svg'
import ln from '../../assets/images/ico-ln.svg'
import tw from '../../assets/images/ico-tw.svg'

const Footer = () => (
  <footer>
    <div className="container">
      {/* REDE SOCIAL */}
      <div className="social-container">
        <img className="logo" src={logo} alt="Corretora Pottencial" />
        <ul className="social-list">
          <li><a href="https://www.facebook.com/PottencialSeguradora" title="Ir para o Facebook"><img src={fb} alt="Facebook" /></a></li>
          <li><a href="https://twitter.com/pottencial" title="Ir para o Twitter"><img src={tw} alt="Twitter" /></a></li>
          <li><a href="https://instagram.com/pottencialseguradora" title="Ir para o Instagram"><img src={ig} alt="Instagram" /></a></li>
          <li><a href="https://www.linkedin.com/company/pottencial-seguradora-s-a" title="Ir para o Linkedin"><img src={ln} alt="Linkedin" /></a></li>
        </ul>
      </div>

      {/* SAC */}
      <div className="sac-container">
        <h3>Sac</h3>
        <ul className="sac-list">
          <li>
            <a href="tel:3121217777" title="Fale por telefone">(31) 2121-7777</a>
          </li>
          <li>
            <a href="tel:08006067688" title="Fale por telefone">0800 606 7688</a>
          </li>
          <li className="marker">
            <a href="https://www.google.com/maps/place/Av.+Raja+Gab%C3%A1glia,+1143+-+Cidade+Jardim,+Belo+Horizonte+-+MG,+30380-103/@-19.9459454,-43.9592027,17z/data=!3m1!4b1!4m5!3m4!1s0xa6979c3e43171f:0x9d8eb8d9d2da614b!8m2!3d-19.9459505!4d-43.957014" target="_blank" rel="noopener noreferrer" title="Ir para o mapa">
              Av. Raja Gabáglia, 1143, 19°andar
              {' '}
              <br />
              {' '}
              Luxemburgo, Belo Horizonte - MG
            </a>
          </li>
        </ul>
      </div>

      {/* MAPA DO SITE */}
      <div className="siteMap">
        <div>
          <h3>Pottencial</h3>
          <ul>
            <li><a href="https://pottencial.com.br/sobre" title="Ir para a página sobre nós">Sobre Nós</a></li>
            <li><a href="https://pottencial.com.br/certidoes-legais" title="Ir para a página Certidões Legais">Certidões Legais</a></li>
            <li><a href="https://pottencial.com.br/consultar-apolice" title="Ir para a página Consultar Apólice">Consultar Apólice</a></li>
            <li><a href="https://pottencial.com.br/trabalhe-conosco" title="Ir para a página Trabalhe Conosco">Trabalhe Conosco</a></li>
          </ul>
        </div>
        <div>
          <h3>Demais Canais</h3>
          <ul>
            <li><a href="https://pottencial.com.br/contato" title="Ir para a página Fale Conosco">Fale Conosco</a></li>
            <li><a href="https://pottencial.com.br/ouvidoria" title="Ir para a página Ouvidoria">Ouvidoria</a></li>
            <li><a href="https://pottencial.com.br/sinistro" title="Ir para a página Sinistro">Sinistro</a></li>
            <li><a href="http://www.ethicsdeloitte.com.br/pottencialseguradora/" title="Ir para a página Canal de Denúncias">Canal de Denúncias</a></li>
          </ul>
        </div>
        <div>
          <h3>Legal</h3>
          <ul>
            <li><a href="https://stdevsiteinst01.blob.core.windows.net/sitefiles/Governanca/Politica-Governanca-Privacidade-Dados.pdf" target="_blank" rel="noopener noreferrer" title="Ir para a página Política de Privacidade">Política de Privacidade</a></li>
            <li><a href="https://stdevsiteinst01.blob.core.windows.net/sitefiles/Governanca/Politica-cookies-v001.pdf" target="_blank" rel="noopener noreferrer" title="Ir para a página Política de Cookies">Política de Cookies</a></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
