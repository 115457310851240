import React from 'react'
import '../../assets/styles/glossary.scss'
import PropTypes from 'prop-types'
import Term from './Term'

const Glossary = ({ terms }) => (

  <div className="container">
    {
        terms.map((term, index) => (
          <div key={index} id={term.reference} className="containerItem">
            {
              term.contents.map((content, contentIndex) => <Term key={`${index}-${contentIndex}`} content={content} />)
            }
          </div>
        ))
      }
  </div>

)

Glossary.propTypes = {
  terms: PropTypes.array.isRequired,
}

export default Glossary
