import React from 'react'
import PropTypes from 'prop-types'

const Letter = ({ terms }) => {
  const letters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
  const updateLetter = () => letters.map((letter) => {
    const index = terms.findIndex((term) => letter === term.reference)
    return {
      label: letter,
      active: index !== -1,
    }
  })

  const handleClick = (e) => {
    const offset = window.screen.width >= 576 && window.screen.width < 992 ? 195 : 145
    const position = document.getElementById(e.target.innerText.toLowerCase()).offsetTop - offset
    window.scroll({ top: position, left: 0, behavior: 'smooth' })
  }

  return (
    <div className="lettersContainer">
      {
        updateLetter().map((letter, index) => (
          <button type="button" onClick={handleClick} disabled={!letter.active} key={index}>
            {letter.label}
          </button>
        ))
      }
    </div>
  )
}

Letter.propTypes = {
  terms: PropTypes.array.isRequired,
}

export default Letter
