import React from 'react'
import Header from './components/Header'
import Glossary from './components/Glossary'
import Footer from './components/Footer'

import cobertura1 from './assets/images/cobertura_1.png'
import cobertura2 from './assets/images/cobertura_2.png'
import cobertura3 from './assets/images/cobertura_3.png'
import corretor1 from './assets/images/corretor_1.png'
import grupo1 from './assets/images/grupo_1.png'
import numeroApolice1 from './assets/images/numero_apolice_1.png'
import sinistro1 from './assets/images/sinistro_1.png'

function App() {
  const terms = [
    {
      reference: 'a',
      contents: [
        {
          title: 'Apólice (Policy ou Insurance Policy)',
          desc: 'É o contrato do seguro, onde os riscos de prejuízo de um objeto são repassados à seguradora, por um preço. A apólice contém as cláusulas e condições gerais, além das coberturas específicas a cada produto. Nela constam quem é o estipulante, o segurado, o beneficiário, a seguradora, dentre outros dados identificadores (Ver também: Número de Apólice).',
          images: [],
        },
      ],
    },
    {
      reference: 'b',
      contents: [
        {
          title: 'Beneficiário (Beneficiary)',
          desc: 'O beneficiário é a pessoa da apólice que tem direito a indenização do seguro em caso de problema (sinistro). O beneficiário pode ser uma pessoa diferente do segurado (que é quem está com o risco protegido) e do estipulante (que é quem está contratando o seguro), dependendo da situação (ver figura abaixo). (Ver também: Estipulante, Segurado).',
          images: [],
        },
      ],
    },
    {
      reference: 'c',
      contents: [
        {
          title: 'Cobertura (Coverage)',
          desc: 'Em uma definição ampla, é o conjunto dos riscos cobertos descritos na apólice.',
          images: [],
        },
        {
          title: 'Cobertura básica (Basic Coverage)',
          desc: 'A cobertura básica é a garantia principal do objeto assegurado que a seguradora deve oferecer ao cliente. As seguradoras podem adicionar outros riscos garantidos dentro da cobertura básica, e podem oferecer coberturas adicionais. A cobertura básica seria o mínimo coberto.',
          images: [
            {
              url: cobertura1,
              caption: 'Ex: No Seguro Incêndio o Estipulante é a imobiliária, o Segurado é o inquilino e o Beneficiário é o dono do imóvel.',
            },
            {
              url: cobertura2,
              caption: 'Ex: No Seguro Automóvel o dono do carro pode ser o Estipulante e o Segurado, pois é quem contrata e quem tem o risco assegurado, e o Beneficiário pode ser um terceiro em caso de um acidente.',
            },
            {
              url: cobertura3,
              caption: 'Ex: No Seguro de Celular o dono do celular é o Estipulante pois é quem contrata, é o Segurado pois tem o risco coberto,  e é o Beneficiário pois recebe a indenização em caso de roubo.',
            },
          ],
        },
        {
          title: 'Comissões (Commissions)',
          desc: 'É a remuneração do profissional que vendeu o seguro e que irá administrar a apólice. Essa comissão só pode ser paga a um corretor de seguros e é a contrapartida da seguradora pelos serviços que o corretor prestou ao segurado. Esses serviços podem ser de diversas naturezas e complexidades, desde esclarecer uma dúvida, até auxiliar em um sinistro.',
          images: [],
        },
        {
          title: 'Condições (Conditions)',
          desc: 'As condições de cobertura descrevem um conjunto de normas e pré-requisitos para validade do seguro. Elas podem ser classificadas em três grupos hierárquicos: as condições gerais, as condições particulares, e as condições especiais (Ver também: Condições Gerais).',
          images: [],
        },
        {
          title: 'Condições Gerais (General Conditions ou General Terms and Conditions)',
          desc: 'São aquelas condições que se aplicam a todas as apólices de um produto, e a todas as coberturas de uma determinada modalidade de seguro.',
          images: [],
        },
        {
          title: 'Corretor (Broker)',
          desc: 'É o profissional especializado e habilitado a intermediar contratos de seguros entre as seguradoras, empresas e os consumidores, sejam eles pessoas físicas ou jurídicas. A Susep autorizava os corretores a exercerem a profissão através um número Susep, mas em 2020 esta regularização foi descontinuada.',
          images: [
            {
              url: corretor1,
              caption: '',
            },
          ],
        },
        {
          title: 'Cotação (Quotation)',
          desc: 'É o cálculo com precificação do seguro, ou seja, o cálculo do preço da transferência do risco para a Seguradora. Através das informações do objeto a ser assegurado, a cotação dá o preço (prêmio) do seguro em determinadas condições.',
          images: [],
        },
      ],
    },
    {
      reference: 'e',
      contents: [
        {
          title: 'Emissão (Issuance)',
          desc: 'É a formalização do seguro. Uma vez criada a Proposta pode haver a Emissão da Apólice. A emissão pode acontecer antes ou após o pagamento do seguro. Uma vez que a proposta foi aceita pelo estipulante/segurado, a seguradora tem 15 dias corridos para decidir se aceita e emitir a apólice',
          images: [],
        },
        {
          title: 'Endosso (Endorsement)',
          desc: 'É um documento emitido pela seguradora durante a vigência do seguro, que tem como objetivo a alteração, modificação ou transferência de dados da apólice. Qualquer tipo de alteração na apólice gera um endosso. Alterar algum dado pode alterar o preço (prêmio) da apólice, e consequentemente gerar um pagamento adicional ou devolução de dinheiro para o estipulante/segurado. O endosso é basicamente uma nova apólice que altera dados da apólice anterior (Ver também: Numeração de apólice).',
          images: [],
        },
        {
          title: 'Estipulante (Policy owner)',
          desc: 'O estipulante é a pessoa que contrata o seguro, independente se é ele que se beneficia do seguro ou se é ele quem vai receber a indenização. É o estipulante, ou dono da apólice, que preenche e assina a proposta de seguro, paga o prêmio, solicita eventuais modificações durante a vigência da apólice, autoriza a sua renovação, ou solicita o seu cancelamento quando for o caso (Ver também: Beneficiário, Segurado).',
          images: [],
        },
      ],
    },
    {
      reference: 'f',
      contents: [
        {
          title: 'Franquia (Deductible)',
          desc: 'É o valor, em dinheiro, que o segurado, deve desembolsar caso ele se envolva em um sinistro e tenha que acionar a seguradora. A franquia pode ser um percentual sobre o valor da cobertura ou pode também ser um valor fixo. Exemplo de franquia: 10% sobre o valor indenizado com mínimo de R$ 500,00.',
          images: [],
        },
      ],
    },
    {
      reference: 'g',
      contents: [
        {
          title: 'Grupo (Group)',
          desc: 'Conjunto de ramos que possuem alguma característica comum (art. 3º da Circular Susep nº 535, de 2016).',
          images: [
            {
              url: grupo1,
              caption: '',
            },
          ],
        },
      ],
    },
    {
      reference: 'i',
      contents: [
        {
          title: 'Importância Segurada (Insured Amount)',
          desc: 'Significa o mesmo que capital segurado e trata-se do valor estabelecido na apólice de seguro para a cobertura contratada, determinando o valor máximo de indenização a ser paga pela seguradora em caso de sinistro.',
          images: [],
        },
      ],
    },
    {
      reference: 'l',
      contents: [
        {
          title: 'LMI - Limite de indenização Máxima (Maximum Insured Amount)',
          desc: 'Possui o mesmo conceito de importância segurada, e trata-se do valor estabelecido na apólice de seguro para a cobertura contratada, determinando o valor máximo de indenização a ser paga pela seguradora em caso de sinistro.',
          images: [],
        },
      ],
    },
    {
      reference: 'm',
      contents: [
        {
          title: 'Modalidade',
          desc: 'Para a Pottencial iremos considerar as modalidades como sendo sub-ramos, podendo haver ou não. (Ex.: Seguro Garantia Licitante Seguro Garantia Judicial, Riscos Diversos Obras de Arte, Compreensivo Residencial Riscos Nomeados etc.)',
          images: [],
        },
      ],
    },
    {
      reference: 'n',
      contents: [
        {
          title: 'Número de apólice (Policy number)',
          desc: 'O número da apólice identifica o contrato na seguradora e na Susep, e obrigatoriamente será sequencial, levando em consideração na sua composição: Código de registro da SUSEP da sociedade seguradora (03069); Ano de emissão da apólice; Sucursal da emissão da apólice (99); Código do ramo de operação na SUSEP; Número sequencial por ramo de operação. EX:',
          images: [
            {
              url: numeroApolice1,
              caption: '',
            },
          ],
        },
      ],
    },
    {
      reference: 'o',
      contents: [
        {
          title: 'Objeto Segurado (Insured Object)',
          desc: 'É uma designação genérica para qualquer interesse que está sendo segurado, sejam coisas, bens, pessoas, responsabilidades, obrigações, direitos e garantias.',
          images: [],
        },
      ],
    },
    {
      reference: 'p',
      contents: [
        {
          title: 'Pagamento (Payment)',
          desc: 'Se trata do recebimento do dinheiro do prêmio do seguro, através de emissão de título (boleto, fatura) ou autorização de débito em conta.',
          images: [],
        },
        {
          title: 'Pessoa Física (Individual)',
          desc: 'A pessoa física, identificada por um CPF, pode ser um estipulante, um segurado ou um beneficiário. Em alguns produtos, como o Garantia ou o Fiança Locatícia, há uma análise de crédito deste CPF (Ver também: Beneficiário, Estipulante, Segurado).',
          images: [],
        },
        {
          title: 'Pessoa Jurídica (Corporation ou Legal Entity)',
          desc: 'Pessoa jurídica, identificada por um CNPJ, pode ser um estipulante, um segurado ou um beneficiário. Em alguns produtos, como o Garantia ou o Fiança Locatícia, há uma análise de crédito deste CNPJ. (Ver também: Beneficiário, Estipulante, Segurado).',
          images: [],
        },
        {
          title: 'Plano',
          desc: 'Determina os modelos de comercialização de um produto. (Ex.: Básico, Tradicional, Estendido, Customizado. etc.).',
          images: [],
        },
        {
          title: 'Prêmio (Premium)',
          desc: 'O prêmio do seguro é o valor que deve ser pago à seguradora por aquele que contrata uma apólice, para garantir os benefícios previstos nela. Podemos falar em prêmio comercial, que se refere apenas ao valor pago pela apólice em si; ou prêmio bruto, que inclui também impostos, carregamento e custo de emissão da apólice.',
          images: [],
        },
        {
          title: 'Prêmio Cobrado (Charged Premium)',
          desc: 'É o valor dos prêmios efetivamente recebidos pela companhia seguradora.',
          images: [],
        },
        {
          title: 'Prêmio Comercial (Commercial Premium)',
          desc: 'É o prêmio efetivamente cobrado dos segurados, correspondendo ao prêmio puro (para cobertura do risco), incluído de carregamento para fazer face às despesas comerciais de corretagem e angariação, de despesas administrativas e de remuneração pelo capital empregado das companhias seguradoras.',
          images: [],
        },
        {
          title: 'Prêmio Líquido (Net Premium)',
          desc: ` 
                  <p> 1) É a diferença entre prêmios auferidos sem impostos e as comissões pagas a título de corretagem pelo seguro ou de resseguro. </p>
                  <p> 2) No seguro de Vida é a porção do prêmio calculado com base em uma determinada tábua de mortalidade e taxa de juro, de forma a possibilitar que o segurador pague benefícios garantidos pelo contrato de seguro, não sendo consideradas despesas, contingências ou lucro. </p>
                  <p> 3) São os valores representados pelos prêmios ganhos ou emitidos por uma seguradora, excetuando-se os do ramo Vida, após dedução das devoluções (por cancelamento de contrato) aos segurados e prêmios pagos por co-seguros ou resseguros.</p>
                `,
          images: [],
        },
        {
          title: 'Prêmio Mínimo (Minimum Premium)',
          desc: 'É a importância mínima que o segurado pode pagar pela cobertura de um risco em razão de uma caracterização específica ou pela fixação de um valor mínimo de prêmio por autoridade competente.',
          images: [],
        },
        {
          title: 'Prêmio Puro (Pure Premium)',
          desc: ` 
                  <p> 1) É o prêmio estatístico em que há margem, isto é, um carregamento de segurança destinado a cobrir as flutuações aleatórias desfavoráveis verificadas na massa que serviu de base para a geração do prêmio estatístico. Teoricamente, portanto, é o prêmio estritamente suficiente para a cobertura do risco, sem expor a seguradora a desvios desfavoráveis de sinistralidade, no período de exposição ao risco. </p>
                  <p> 2) É a parcela do prêmio que é suficiente para pagar sinistros e as respectivas despesas de regulação e liquidação. </p>
                  <p> 3) É o prêmio calculado pela divisão dos prejuízos pelas unidades de exposição ao risco, sem considerar qualquer carregamento a título de comissão, taxas e despesas. </p>
                  <p> 4) No Seguro Rural (cobertura de queda de granizo em colheita) é a razão entre sinistros ocorridos, os quais resultarão em pagamento de indenização e a responsabilidade assumida.</p>
                `,
          images: [],
        },
        {
          title: 'Prêmio Retido (Retained Premium)',
          desc: 'Nas operações de resseguro proporcional é o prêmio que fica com o segurador cedente na exata proporção da sua retenção',
          images: [],
        },
        {
          title: 'Produto  (Product)',
          desc: 'Plano de seguro, plano de previdência complementar aberta, plano referente à título de capitalização ou plano de micros seguro, refere-se ao número de processos retornado pela Susep para a sua comercialização.',
          images: [],
        },
        {
          title: 'Proposta (Proposal)',
          desc: 'É um documento espelho do que será a apólice. Serve como documento temporário enquanto a apólice não fica pronta. Deve conter assinatura do segurado.',
          images: [],
        },
      ],
    },
    {
      reference: 'r',
      contents: [
        {
          title: 'Ramo (Line)',
          desc: "Referente ao conjunto de coberturas diretamente relacionadas ao produto. A nomenclatura 'ramo' é aplicável a planos de seguros (Circular Susep nº 535, de 2016) e micros seguros, os 'assuntos' dizem respeito à previdência complementar aberta e as modalidades são referentes a títulos de capitalização.",
          images: [],
        },
      ],
    },
    {
      reference: 's',
      contents: [
        {
          title: 'Segurado (Insured)',
          desc: `
                  <p>O segurado é a pessoa, física ou jurídica, cujos interesses estão garantidos pela apólice de seguro. Geralmente é quem contrata o seguro.</p>
                  <p>Exemplo: Um inquilino contrata um seguro para assegurar o imóvel que está alugando (ele é o segurado). Caso o imóvel pegue fogo, o dono do imóvel é quem vai receber a indenização (o dono do imóvel é o beneficiário) (Ver também: Beneficiário, Estipulante).</p>
                `,
          images: [],
        },
        {
          title: 'Seguradora (Insurer)',
          desc: 'É a empresa cujo objeto é a celebração de seguros. No Brasil, as seguradoras são organizadas sob a forma de sociedades anônimas, sendo permitido também que o façam sob a forma de cooperativas exclusivamente destinadas a atuar com seguros agrícolas ou de saúde.',
          images: [],
        },
        {
          title: 'Seguradora Cativa',
          desc: 'Seguradora de propriedade de uma empresa ou de uma corporação – até o momento da sua organização sem ligações com a atividade seguradora – constituída com a finalidade exclusiva de segurar os riscos provenientes das suas atividades empresariais e, assim, obter ganhos diretos e aperfeiçoar o gerenciamento daqueles riscos.',
          images: [],
        },
        {
          title: 'Seguradora Cedente',
          desc: 'É aquela que cede em resseguro os excessos de sua capacidade retentiva.',
          images: [],
        },
        {
          title: 'Seguradora Direta',
          desc: 'É aquela empresa com a qual o segurado contrata diretamente a cobertura para os seus riscos. Essa expressão é comumente utilizada quando existe co-seguro ou resseguro para o mesmo risco.',
          images: [],
        },
        {
          title: 'Seguradora Fusão',
          desc: 'É a reunião de duas ou mais seguradoras para formação de uma nova seguradora.',
          images: [],
        },
        {
          title: 'Seguradora Incorporação',
          desc: 'Operação pela qual uma ou mais seguradoras são absorvidas por outra, que lhes sucede em todos os direitos e obrigações.',
          images: [],
        },
        {
          title: 'Seguradora Líder',
          desc: 'É a seguradora que compartilha o mesmo risco com outra(s) seguradora(s), sendo a responsável, em geral, pela maior quota e pela administração da apólice.',
          images: [],
        },
        {
          title: 'Seguradora Vinculada',
          desc: 'A seguradora que controla ou é controlada direta ou indiretamente por outras, ou, ainda, aquelas que estejam sob controle comum, direto ou indireto, ainda que não exercido por seguradora.',
          images: [],
        },
        {
          title: 'Sinistro (Claim)',
          desc: 'Refere-se a qualquer evento em que o bem segurado sofre um acidente ou prejuízo material. Representa a materialização do risco, causadora de perdas financeira para a seguradora. Em um sinistro geralmente o beneficiário da apólice deverá ser indenizado.',
          images: [
            {
              url: sinistro1,
              caption: '',
            },
          ],
        },
        {
          title: 'Subscrição (Underwriting)',
          desc: 'É a análise do risco pela Seguradora. Ela analisa as informações do objeto a ser assegurado e toma a decisão de aceitar ou não aceitar assegurar tal objeto. Em caso positivo, ela determina com quais condições aceitará e com qual preço (prêmio).',
          images: [],
        },
        {
          title: 'Sucursal (Branch Office)',
          desc: 'É a filial de um estabelecimento, empresa, instituição pública ou particular, subordinado a uma matriz.',
          images: [],
        },
        {
          title: 'SUSEP (Susep)',
          desc: 'Superintendência de Seguros Privados - é um órgão público que regulamenta o bom funcionamento do setor de seguros,',
          images: [],
        },
      ],
    },
    {
      reference: 't',
      contents: [
        {
          title: 'Taxa (Rate)',
          desc: 'Valor aplicado a importância segurada (IS) que corresponde ao risco e ao custo operacional da cobertura. É o elemento necessário a fixação do prêmio.',
          images: [],
        },
        {
          title: 'Taxa de Carregamento (Loading Fee)',
          desc: 'A taxa de carregamento é uma porcentagem de cobrança (agravo) acerca de um valor segurado.',
          images: [],
        },
        {
          title: 'Tomador (Policy Holder)',
          desc: 'Devedor das obrigações por ele assumidas no contrato principal. (Circular SUSEP 232/03).',
          images: [],
        },
      ],
    },
    {
      reference: 'v',
      contents: [
        {
          title: 'Vigência (Policy Period)',
          desc: 'A vigência é o prazo que determina o início e o fim da validade das coberturas contratadas. Esta informação precisa estar expressa na apólice. A maioria dos seguros geralmente vigoram por um ano.',
          images: [],
        },
      ],
    },
  ]
  return (
    <main>
      <Header terms={terms} />
      <Glossary terms={terms} />
      <Footer />
    </main>
  )
}

export default App
