import React from 'react'
import '../../assets/styles/header.scss'
import PropTypes from 'prop-types'
import logo from '../../assets/images/logo.svg'
import Letter from './Letter'

const Header = ({ terms }) => (
  <>
    <header>
      <div className="container">
        <a href="https://pottencial.com.br/" title="Pottencial seguradora"><img src={logo} alt="COrretora Pottencial" /></a>
      </div>
    </header>
    <div className="banner">
      <div className="container">
        <h1>
          Glossário
          <br />
          {' '}
          Pottencial
        </h1>
      </div>
    </div>
    <div className="optionsContainer">
      <div className="container">
        <Letter terms={terms} />
      </div>
    </div>
  </>
)

Header.propTypes = {
  terms: PropTypes.array.isRequired,
}

export default Header
